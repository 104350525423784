import React, { useState, useEffect } from 'react';

function AudioPlayer({ name, fileName, breaks, volume }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [lastTimeSliderActive, setLastTimeSliderActive] = useState(0);
    const [currentVolume, setCurrentVolume] = useState(volume / 100);

    const audio = new Audio(fileName);
    audio.volume = currentVolume;

    useEffect(() => {
        let timer;

        function playAudioWithBreaks() {
            audio.play().then(() => {
                timer = setTimeout(() => {
                    if (isPlaying) playAudioWithBreaks();
                }, audio.duration * 1000 + breaks);
            });
        }

        if (isPlaying) {
            playAudioWithBreaks();
        }

        return () => {
            clearTimeout(timer);
            audio.pause();
            audio.currentTime = 0;
        };
    }, [isPlaying, audio, breaks]);

    const handleVolumeChange = (e) => {
        const volumeValue = e.target.value ;
        setCurrentVolume(volumeValue);
        setLastTimeSliderActive( new Date());
        audio.pause();
        setIsPlaying(false);
        audio.volume = volumeValue;
        if(isPlaying){
            setTimeout(()=> {
                var date = new Date();
                if( date - lastTimeSliderActive >400){
                    setIsPlaying(true)
                }
                },500)
        }
    };

    return (
        <div className="audio-player-container">
            <button 
                onClick={() => setIsPlaying(!isPlaying)}
                className={isPlaying ? 'active' : 'inactive'}
            >
                {isPlaying ? 'On' : 'Off'}
            </button>
            <span className="audio-name">{name}</span>
            <input 
                type="range" 
                min="0" 
                max="1" 
                step="0.01" 
                value={currentVolume} 
                onChange={handleVolumeChange}
            />
            <span className="audio-volume">{currentVolume * 100}%</span>
        </div>
    );
}

export default AudioPlayer;