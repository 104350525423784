import logo from './logo.svg';
import './App.css';
import AudioPlayer from './AudioPlayer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Farm Audio by <img src="logo_hoch.svg" width="200px"/>
        </h1>
      </header>
      <body>
        <h1>Select some sounds to create your farm 🔊</h1><button class="audio-button" id="mute-button">Mute</button>
        <table class="topics">
          <tr>
            <td class="typebox">
              <h3>Machinery 🚜</h3>
              <AudioPlayer fileName="sounds/baler.mp3" breaks="0" name={"Baler"} volume={50} />
              <AudioPlayer fileName="sounds/transport_wagon.mp3" breaks="100" name={"Transport wagon"} volume={50} />
              <AudioPlayer fileName="sounds/chopper.mp3" breaks="200" name={"Chopper"} volume={50} />
              <AudioPlayer fileName="sounds/tractor.m4a" breaks="100" name={"Tractor"} volume={50} />
            </td>
            <td class="typebox">
              <h3>Environment 🌱</h3>
              <AudioPlayer fileName="sounds/thunder.mp3" breaks="0" name={"ThunderStorm"} volume={50} />
              <AudioPlayer fileName="sounds/wind-in-maize.mp3" breaks="0" name={"Wind in the maize field"} volume={50} />
              <AudioPlayer fileName="sounds/church-bell.mp3" breaks="900" name={"Church in a distance"} volume={30} />
            </td>
            <td class="typebox">
              <h3>FarmLife 🧑‍🌾</h3>
              <AudioPlayer fileName="sounds/small-dog-barking.mp3" breaks="0" name={"Farm Dog"} volume={100} />
              <AudioPlayer fileName="sounds/cows.mp3" breaks="1000" name={"Cows"} volume={50} />
              <AudioPlayer fileName="sounds/cowbells.mp3" breaks="1000" name={"CowBells"} volume={20} />
              <AudioPlayer fileName="sounds/rooster.wav" breaks="1000" name={"Rooster"} volume={20} />
              <AudioPlayer fileName="sounds/chicken.mp3" breaks="1000" name={"Chicken"} volume={20} />
            </td>
            {/* <td class="typebox">
              <h3>Add the sound of missing digitalization 📟</h3>
              <AudioPlayer fileName="sounds/phone.mp3" breaks="500" name={"No fleet management"} volume={40} />
              <AudioPlayer fileName="sounds/broken-isoxml.mp3" breaks="0" name={"Inkompatible TaskSet"} volume={100} />
              <AudioPlayer fileName="sounds/no-apis.mp3" breaks="0" name={"No API connections"} volume={100} />
              <AudioPlayer fileName="sounds/wrong-application-map.mp3" breaks="0" name={"Spraying a wrong application map"} volume={100} />
            </td> */}
          </tr>
        </table>
        
        <div class="topics">
          <div class="typebox" id="missing_sounds">
          <h3>Add the sound of missing digitalization</h3>
            <AudioPlayer fileName="sounds/phone.mp3" breaks="1000" name={"Phone ringing"} volume={40} />
            <AudioPlayer fileName="sounds/broken-isoxml.mp3" breaks="0" name={"Broken ISOXML"} volume={100} />
            <AudioPlayer fileName="sounds/no-apis.mp3" breaks="0" name={"Missing Connectivity to the authorities"} volume={100} />
            <AudioPlayer fileName="sounds/wrong-application-map.mp3" breaks="0" name={"Spraying a wrong application map"} volume={100} />
            <AudioPlayer fileName="sounds/usb-data-exchange.mp3" breaks="0" name={"Still using USB sticks"} volume={100} />
          </div>
          <div class="secondtopic">
            <img src="arrow.svg"  class="arrow"/>
          </div>
          <div class="typebox">
          <a
            className="App-link"
            href="https://dev4Agriculture.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="logo_hoch.svg" id='footer_logo'/><br></br><br></br>
            Add some digitalisation
          </a>
          </div>
        </div>
        {/* <div class="footer">
          <a
            className="App-link"
            href="https://dev4Agriculture.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="logo_hoch.svg" />
            Add some digitalisation
          </a>
        </div> */}
        <div class="footer">
          <p class="link">Copyright 2023 by dev4Agriculture</p>
        </div>
      </body>
    </div>
  );
}

export default App;


